.sbl-circ-dual {
    height: 48px;
    width: 48px;
    color: #da7dc5;
    display: inline-block;
    position: relative;
    border: 5px solid;
    border-radius: 50%;
    border-top-color: transparent;
    animation: rotate 1s linear infinite;
}
.sbl-circ-dual::after {
    content: "";
    height: 40%;
    width: 40%;
    display: block;
    color: inherit;
    margin: 10px auto;
    position: relative;
    border: 5px solid;
    border-radius: 50%;
    border-top-color: transparent;
    animation: rotate 0.5s linear infinite reverse;
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

.ellipsis-anim span {
    opacity: 0;
    -webkit-animation: ellipsis-dot 1s infinite;
    animation: ellipsis-dot 1s infinite;
}

.ellipsis-anim span:nth-child(1) {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}
.ellipsis-anim span:nth-child(2) {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}
.ellipsis-anim span:nth-child(3) {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

@-webkit-keyframes ellipsis-dot {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes ellipsis-dot {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
