@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Estafet";
    src: url("assets/fonts/Estafet.otf");
    letter-spacing: 35px;
}

* {
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
    letter-spacing: 8px;
}

body {
    background-color: #23202a;
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}
::-webkit-scrollbar-thumb {
    background: #da7dc5;
    border: 0px none #ffffff;
    border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
    background: #da7dc5;
}
::-webkit-scrollbar-thumb:active {
    background: #da7dc5;
}
::-webkit-scrollbar-track {
    background: #23202a;
    border: 0px none #ffffff;
    border-radius: 0px;
}
::-webkit-scrollbar-track:hover {
    background: #23202a;
}
::-webkit-scrollbar-track:active {
    background: #23202a;
}
::-webkit-scrollbar-corner {
    background: transparent;
}

::selection {
    background: #da7dc5; /* WebKit/Blink Browsers */
}
::-moz-selection {
    background: #da7dc5; /* Gecko Browsers */
}
